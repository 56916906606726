import Enum from '@/utils/Enum';

const ProfessionEnum = [
  { type: 'MAINTENANCE_ENGINEER', label: '维修工程师' },
  { type: 'MEDICAL_WORKERS', label: '客户' },
  { type: 'SALESMAN', label: '销售' },
  { type: 'PURCHASE', label: '采购' },
  { type: 'ADMINISTRATIVE', label: '行政' },
  { type: 'FINANCE', label: '财务' },
  { type: 'ENTERPRISE_PRINCIPAL', label: '企业负责人' },
  { type: 'OTHER', label: '其他' },
];

export default new Enum(ProfessionEnum);
export { ProfessionEnum };
