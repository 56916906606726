<template>
  <div class="empty-box" :style="{'height': `${height}px`}">
    <img :src="require('@/assets/imgs/workbench/bg_empty.svg')" class="img" />
    <div>暂无数据</div>
  </div>
</template>
<script>
export default {
  props: {
    height: {
      type: Number,
      default: 250,
    },
  },
};
</script>
<style lang="scss" scoped>
  .empty-box {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #929AA6;
    .img {
      width: 88px;
      height: 88px;
    }
  }
</style>
